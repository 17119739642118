// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
};

export const firebaseConfig = {
  apiKey: 'AIzaSyDnZ9x9pCNiQJg4gIFsvhX6co3y1-yDcvs',

  authDomain: 'cvivo-admin-2020.firebaseapp.com',

  databaseURL: 'https://cvivo-admin-2020.firebaseio.com',

  projectId: 'cvivo-admin-2020',

  storageBucket: 'cvivo-admin-2020.appspot.com',

  messagingSenderId: '36543790456',

  appId: '1:36543790456:web:5da232d489e91536f65c8d',

  measurementId: 'G-8NPP3NRZ1P',

  timestampsInSnapshots: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
